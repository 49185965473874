import { Link, useLocation, useNavigate } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import "./CourseContent.scss";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import MarkChapter from "./MarkChapter";
import Scrollbar from "react-scrollbars-custom";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";

const CourseContent = () => {
  const navigate = useNavigate();
  const [courseInfo, setCourseInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [completedChapter, SetCompletedChapter] = useState([]);
  const [quizStatus, setQuizStatus] = useState();
  const [quizInfo, setQuizInfo] = useState();
  const [message, setMessage] = useState({
    textMssg: "",
    imgName: "",
  });
  const [customAlert, setCustomAlert] = useState();

  const location = useLocation();
  const { permissions } = usePermissions();
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const stdClassInfo = JSON.parse(localStorage.getItem("classInfo"));
  const searchParams = new URLSearchParams(location.search);
  const financialYear = localStorage.getItem("AY");

  const courseId = searchParams.get("id");
  const fullname = searchParams.get("fullname");
  const stdclass = searchParams.get("stdclass");
  const section = searchParams.get("section");
  const classid = searchParams.get("classid");
  const studentName = userinfoObject[0]?.fullname || "Student";
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const courseName = fullname;



  const openNewAssessmentForm = (section) => {
    console.log(section, "open new form");
    navigate(`/createquiz?courseid=${courseId}&section=${section}`);
  };
  function processObject(obj) {
    if (obj.modules && obj.modules.length > 1) {
      obj.modules.push({
        name: "Add Assessment",
        url: "add",
        section: obj.section,
      });
    }
    if (obj.child && obj.child.length > 0) {
      obj.child.forEach((childObj) => processObject(childObj));
    }
  }

  const addAssessment = (data) => {
    if (userinfoObject[0].department !== "student") {
      data.forEach((obj) => processObject(obj));
      return data;
    } else {
      return data;
    }
  };
  

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const courseContentURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_course_get_contents_info&moodlewsrestformat=json&courseid=${courseId}`;
        const response = await axios.get(courseContentURL);
        setCourseInfo(addAssessment(response.data));
        // setCourseInfo(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching course info:", error);
        setError(true);
        setLoading(false);
      }
    };

    if (courseId && token) {
      fetchCourseDetails();
    }
  }, [courseId, token]);

  const handleOpenActivity = ({ instance }) => {
    const createLink = (scormid) => {
      return `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${instance}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;
    };

    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${instance}`)
      .then((response) => {
        window.open(createLink(response.data.id), "");
      });
  };

  const viewAttemptsHandler = async (modValueID, title) => {
    try {
      const studentListURL = `${
        process.env.REACT_APP_wsPrefix
      }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${courseId}&schoolcode=${Number(
        userinfoObject[0]?.institution
      )}&class=${stdclass}&section=${section}`;

      const response = await axios.get(studentListURL);
      const studentsArray = Object.values(response.data);
      const studentsList = studentsArray.filter(
        (user) => user.department?.toLowerCase() === "student"
      );

      navigate("/GetCourseContent/modAccessInformation/quizstudentlist", {
        state: {
          list: studentsList,
          title: title,
          quizID: modValueID,
        },
      });
    } catch (error) {
      console.error("Error fetching student list:", error);
    }
  };
  function convertToMinutes(timeLimit) {
    let totalMinutes = 0;

    const hourMatch = timeLimit.match(/(\d+)\s*hours?/i);
    const minuteMatch = timeLimit.match(/(\d+)\s*mins?/i);

    if (hourMatch) {
      totalMinutes += parseInt(hourMatch[1], 10) * 60;
    }

    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1], 10);
    }

    return totalMinutes;
  }
  const handleAlertClose = () => {
    setCustomAlert(false);
  };

  const startAttemptHandler = async (moduleData) => {
    console.log(moduleData, "startAttempt");

    const getQuizStatusURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/quiz/quizattempt.php?wsfunction=getquiz_attemptdata&wstoken=${token}`;
    const quizInfoURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_quiz_access_information&moodlewsrestformat=json&quizid=${moduleData.instance}`;
    const attemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_start_attempt&moodlewsrestformat=json&quizid=${moduleData.instance}`;

    const parameters = {
      quiz_id: moduleData.instance,
      user_id: userinfoObject[0].id,
    };

    try {
      const quizStatusResponse = await axios.post(
        getQuizStatusURL,
        JSON.stringify(parameters)
      );
      setQuizStatus(quizStatusResponse.data);
      const quizInfoResponse = await axios.get(quizInfoURL);
      setQuizInfo(quizInfoResponse.data);

      let quizProgress = null;
      if (quizStatusResponse.data) {
        const quizStatusData = quizStatusResponse.data;
        if (quizStatusData[0].length < 1) {
          quizProgress = 0;
        } else if (quizStatusData[1][0] === "inprogress") {
          console.log("Inprogress");
          quizProgress = 1;
        } else if (quizStatusData[1][0] === "finished") {
          console.log("finished");
          quizProgress = 2;
        }
      }
      const totalTime = convertToMinutes(quizInfoResponse.data?.accessrules[1]);
      if (quizProgress === 0) {
        const attemptResponse = await axios.get(attemptURL);
        if (!attemptResponse.data.attempt.id) {
          setCustomAlert(true);
          setMessage({
            textMssg: "Date is passed for this assignment",
            imgName: "overtime",
          });
        } else {
          navigate(
            `/AssessmentAttempt?id=${attemptResponse.data.attempt.id}&quizId=${moduleData.instance}&totalTime=${totalTime}`
          );
        }
      } else if (quizProgress === 1) {
        navigate(
          `/AssessmentAttempt?id=${quizStatusResponse.data[0][0]}&quizId=${moduleData.instance}&totalTime=${totalTime}`
        );
      } else if (quizProgress === 2) {
        setCustomAlert(true);
        setMessage({
          textMssg: "You have already completed this assignment attempt.",
          imgName: "completetask",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const imageName = (value) => value.replace(/[\s:,\d-_]/g, "").toLowerCase();

  const renderModules = (modules) => {
    return modules.map((module) => (
      <Box
        className="assetsBoxes"
        key={module.id}
        onClick={() => {
          if (module.modname === "quiz") {
            if (userinfoObject[0].department === "student") {
              startAttemptHandler(module);
            } else {
              viewAttemptsHandler(module.id, module.name);
            }
          } else if (module.name === "Add Assessment") {
            openNewAssessmentForm(module.section);
          } else {
            handleOpenActivity(module);
          }
        }}
      >
        <Box className="titleFace" sx={{ color: "textsColor.secColor" }}>
          {module.name}
        </Box>
        <Box className={`imgBox`}>
          <img
            src={`${
              module.modname === "quiz"
                ? `${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/assessment.png`
                : `${
                    process.env.REACT_APP_wsPrefix
                  }/lms-assets/icons/courseIcons/${imageName(module.name)}.png`
            }`}
            className="assetOverlayImg"
            alt={module.name}
          />
        </Box>
        <Box className={`overlayBox`}>
          <Box className="topIcon">{module.name}</Box>
        </Box>
      </Box>
    ));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
          {
            courseid: courseId,
            classid: classid,
            teacherid: userinfoObject[0].id,
            financial_year: financialYear,
            schoolcode: userinfoObject[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        SetCompletedChapter(response?.data.data || []);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const completedStatusMap = useMemo(() => {
    const map = {};
    completedChapter.forEach((chapter) => {
      map[chapter.course_sectionid] = chapter.status === 1;
    });
    return map;
  }, [completedChapter]);

  const renderAccordion = (data) => (
    <Accordion key={data.id} className="customAccordion">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${data.id}-content`}
        id={`panel-${data.id}-header`}
        className="accordionSumm"
      >
        {data.name}
        {permissions.markChapter?.write && permissions.markChapter?.read && (
          <>
            {data.child?.length === 0 && data.summary.includes("Chapter") && (
              <MarkChapter
                courseID={courseId}
                valChild={data}
                classid={classid}
                userinfoObject={userinfoObject}
                completedChapter={completedStatusMap[data.id]}
              />
            )}
          </>
        )}
      </AccordionSummary>
      <AccordionDetails
        className={`accordionDetail ${
          data.child?.length > 0 ? "insideAccordion" : "mainAccordion"
        }`}
      >
        <Box className="activityContent">
          {data.child?.length > 0
            ? data.child.map((child) => renderAccordion(child, completedStatusMap))
            : renderModules(data.modules)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
  
  return (
    <MainCard
      cardTitle={`${courseName} ${stdclass ? `${stdclass}${section}` : ""}`}
      additionalData={
        <>
          {userinfoObject[0]?.department?.toLowerCase() === "pedagogy" && (
            <Link
              className="btn linkBtn primaryBtn"
              to={`/GetCourseContent/allStudentsReport?courseid=${courseId}&coursename=${courseName}&stdclass=${stdclass}&section=${section}`}
            >
              Progress Report
            </Link>
          )}
          {userinfoObject[0]?.department?.toLowerCase() === "student" && (
            <Link
              className="btn linkBtn primaryBtn"
              to={`/GetCourseContent/StudentReport?id=${stdClassInfo?.id}&studentName=${studentName}&courseid=${courseId}&coursename=${courseName}&stdclass=${stdClassInfo?.class}&section=${stdClassInfo?.section}`}
            >
              View Progress
            </Link>
          )}
        </>
      }
    >
      <Scrollbar
        style={{ width: "100%", height: `calc(72vh - 20px)` }}
        className="customCardItem"
      >
        {loading ? (
          <CircularLoader />
        ) : (
          <>
            {error || !courseInfo ? (
              <Typography color="error" align="center">
                Failed to load course content.
              </Typography>
            ) : (
              <>
                {courseInfo.map(
                  (data) => data.visible && renderAccordion(data)
                )}
              </>
            )}
          </>
        )}
      </Scrollbar>
      {customAlert && (
        <CustomDialog isOpen={customAlert} onClose={handleAlertClose}>
          <DialogContent sx={{ textAlign: "center" }}>
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/${message.imgName}.png`}
              alt="completetask"
              loading="lazy"
              width={"150px"}
            />
            <Typography> {message.textMssg}</Typography>

            <PrimaryBgBtn
              onClick={handleAlertClose}
              style={{ marginTop: "20px" }}
            >
              Close
            </PrimaryBgBtn>
          </DialogContent>
        </CustomDialog>
      )}
    </MainCard>
  );
};

export default CourseContent;
